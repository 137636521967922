<template>
  <v-main class="natural lighten-3">
    <v-container fluid class="natural lighten-3">
      <v-row>
        <v-col cols="4"><StepCardActive :title="'ข้อมูลส่วนตัว'" /></v-col>
        <v-col cols="4"><StepCardInActive :title="'ข้อมูลสุขภาพ'" /></v-col>
        <v-col cols="4"><StepCardInActive :title="'สำเร็จ'" /></v-col>
      </v-row>
    </v-container>
    <v-container fluid class="natural">
      <v-row>
        <v-col cols="12">
          <v-card elevation="0">
            <v-card-title>ข้อมูลส่วนตัว</v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <TextField
                  v-model="citizenObj.id_number"
                  v-mask="'#-####-#####-##-#'"
                  :title="'บัตรประจำตัวประชาชน'"
                  :placeholder="'หมายเลขหน้าบัตร 13 หลัก'"
                  required
                  :rules="[(v) => !!v || 'กรุณากรอกข้อมูล', v => (v && v.length >= 17) || 'กรุณากรอกข้อมูลให้ครบถ้วน']"
                />
                <TextField
                  v-model="citizenObj.name_th"
                  :title="'ชื่อ-นามสกุล'"
                  required
                  :rules="[(v) => !!v || 'กรุณากรอกข้อมูล']"
                />
                <TextArea
                  v-model="citizenObj.address_th"
                  :title="'ที่อยู่'"
                  required
                  :rules="[(v) => !!v || 'กรุณากรอกข้อมูล']"
                />
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-spacer />
        <v-col cols="12">
          <v-btn block large color="primary text-title-2" @click="validate"
            >ถัดไป</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
// import Vue from 'vue'
import StepCardActive from "@/components/form/StepCardActive";
import StepCardInActive from "@/components/form/StepCardInactive";
import TextField from "@/components/form/TextField";
import TextArea from "@/components/form/TextArea";
import { mask } from "vue-the-mask";
import { mapFields } from "vuex-map-fields";


export default {
  components: {
    StepCardActive,
    StepCardInActive,
    TextField,
    TextArea,
  },
  directives: { mask },
  data() {
    return {
      valid: true,
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$router.replace({ name: "inputform" });
      }
    },
  },
  computed: {
    ...mapFields(["citizenObj"]),
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
</style>