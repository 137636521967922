<template>
  <div>
       <label class="text-title-3">{{title}}

       <!-- </div> -->
        <v-textarea outlined   
        v-bind="$attrs"
        v-bind:value="value"
        v-on="inputListeners"
        ></v-textarea>
       </label>
  </div>
</template>

<script>
export default {
 inheritAttrs: false,
  props: ['title', 'value'],
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }}
}
</script>

<style lang="scss" scoped>

</style>